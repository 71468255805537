import { Navigate, useLocation } from "react-router-dom";
import { getCognitoUser, getCognitoUserTokens } from "../auth/cognito";
import { useEffect, useState } from "react";

export function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  const [authorized, setAuthorized] = useState(!!getCognitoUser());

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCognitoUserTokens();
      } catch (error) {
        setAuthorized(false);
      }
    };
    fetchData();
  }, []);

  if (!authorized) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}
