import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { LoadingButton } from "@mui/lab";

import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { getCognitoUser, userPool } from "../auth/cognito";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultTheme = createTheme();

export default function SignIn() {
  const [singingIn, setSigningIn] = React.useState(false);

  let location = useLocation();

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setSigningIn(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email") as string;
    const password = data.get("password") as string;

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setSigningIn(false);
        navigate("/portfolio");
      },
      onFailure: (err) => {
        setSigningIn(false);
        toast.error(err.message, {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          // pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      },
      newPasswordRequired(userAttributes, requiredAttributes) {
        setSigningIn(false);
        console.log("newPasswordRequired");
      },
    });
  };

  if (!!getCognitoUser()) {
    return <Navigate to="/portfolio" state={{ from: location }} replace />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer style={{ fontSize: "14px" }} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 0 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <LoadingButton
              type="submit"
              loading={singingIn}
              disableRipple
              variant="contained"
              sx={{ mt: 2, width: "50%" }}
            >
              Sign In
            </LoadingButton>
          </Box>
        </Box>
        {/* {singingIn && <CircularProgress sx={{ mt: 2 }} size={30} />} */}
      </Container>
    </ThemeProvider>
  );
}
