import React, { useRef, useState } from "react";
import { signOut } from "../auth/cognito";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";
import ExampleImg from "../Logo_La_Linea_100x100.png";
import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

const Wrapper = styled.div`
  max-width: 500px;
  width: calc(100% - 40px); /* Adjusts the width considering the extra 40px */
  margin: 0 auto; /* Keeps the div centered */
`;

const borderRadius = "7px";

const PaperWrapper = ({ children }: { children: JSX.Element }) => {
  const [elevation, setElevation] = useState(2); // State for elevation

  const onMouseEnter = () => setElevation(4);
  const onMouseLeave = () => setElevation(2);
  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 0,
        ":hover": { background: grey[100] },
        borderRadius: borderRadius,
        backgroundColor: grey[50],
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      elevation={elevation}
    >
      {children}
    </Paper>
  );
};

export const Portfolio = () => {
  const navigate = useNavigate();

  const signOutHandler = () => {
    signOut();
    navigate("/");
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h1>Kaloyan's Portfolio</h1>
        </div>
        <div style={{ height: "50%" }}>
          <IconButton onClick={signOutHandler} aria-label="delete" size="large">
            <LogoutIcon fontSize="inherit" />
          </IconButton>
        </div>
      </div>

      {/* <button onClick={signOutHandler}>Sign out</button> */}
      <PaperWrapper>
        <>
          <div style={{ display: "flex" }}>
            <div style={{ height: "120px", width: "130px" }}>
              <img
                src={ExampleImg}
                style={{
                  borderRadius: `${borderRadius} 0px 0px ${borderRadius}`,
                  maxWidth: "120px",
                }}
                alt="test"
              />
            </div>
            <div style={{ paddingTop: "10px" }}>
              <div style={{}}>Application name</div>
              <div>Stack</div>
            </div>
          </div>
        </>
      </PaperWrapper>
      <PaperWrapper>
        <>
          <div>Application name</div>
          <div>Stack</div>
          <div>Image</div>
        </>
      </PaperWrapper>
      <PaperWrapper>
        <>
          <div>Application name</div>
          <div>Stack</div>
          <div>Image</div>
        </>
      </PaperWrapper>
    </Wrapper>
  );
};
