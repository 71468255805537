import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Portfolio } from "./components/Portfolio";
import { RequireAuth } from "./components/RequireAuth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    // Component: App,
    element: <App />,
    // element: <div>Hello world!</div>,
  },
  {
    path: "/portfolio",
    element: (
      <RequireAuth>
        <Portfolio />
      </RequireAuth>
    ),
    // element: <div>You have signed in!</div>,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />

    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
