import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: "eu-west-2_uGXCOnkyx",
  ClientId: "7slvico5p8sa6n39vqbri61sr7",
};

export const userPool = new CognitoUserPool(poolData);

export const getCognitoUser = () => userPool.getCurrentUser();

export const signOut = () => {
  const cognitoUser = getCognitoUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export async function getCognitoUserTokens() {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser();

    const cb = () => (err: any, session: any) => {
      if (err) {
        reject(err);
      }

      if (session?.isValid()) {
        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();
        resolve({ idToken, accessToken });
      } else {
        reject(new Error("Session is invalid"));
      }
    };

    if (cognitoUser) {
      cognitoUser.getSession(cb);
    }
  });
}
